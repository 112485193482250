import React, { useEffect, useRef, useState } from 'react'
import uploadGIF from '../img/add-file.gif'
import downloadGIF from '../img/download-file.gif'
import { SnackbarComp } from '../compnents/snackbar'
import * as XLSX from "xlsx";
import Papa from 'papaparse'
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress, Slide } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CSVLink } from 'react-csv';
import { Helmet } from 'react-helmet-async';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Convert() {
    const [file, setfile] = useState([])
    const [FileName, setFileName] = useState('')
    const hiddenFileInput = useRef(null);
    const hiddenFileDownload = useRef(null);
    let fileHeaders

    const [Loading, setLoading] = useState(false)


    const [open, setOpen] = useState(false)
    const [barType, setbarType] = useState('success')
    const [massege, setmassege] = useState('')

    const [openDialog, setOpenDialog] = useState(false);

    const handleChange = (event) => {
        setLoading(true)
        let file = event.target.files[0];
        let lastThree = file.name.slice(-3);
        if (lastThree === 'csv') {
            handleChangeCSV(event)
        }
        else if (lastThree === 'lsx') {
            handleChangeXLSX(event)
        }

    }

    const handleChangeCSV = event => {
        let file = event.target.files[0];
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: function (results) {
                let x = results.data
                x.pop()
                fileHeaders = Object.keys(x[0])
                setLoading(false)
                setfile(x)
                setFileName(file.name)
                setbarType('success')
                setmassege('File uploaded successful')
                setOpen(true)
            }
        });
    };

    const handleChangeXLSX = async (e) => {
        let file = e.target.files[0];
        const data = await file.arrayBuffer()
        const workbook = XLSX.read(data)
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const json_data = XLSX.utils.sheet_to_json(worksheet)
        let x = json_data
        x.pop()
        fileHeaders = Object.keys(x[0])
        setLoading(false)
        setfile(x)
        setFileName(file.name)
        setbarType('success')
        setmassege('File uploaded successful')
        setOpen(true)

    };


    const handleClickOpen = (e) => {
        if (!FileName) {
            setbarType('error')
            setmassege('Upload file first')
            setOpen(true)
        }
        else {
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setLoading(false)
        setOpenDialog(false);
    };


    const handleCSV = (e) => {
        hiddenFileDownload.current.link.click()
        handleClose()

    }

    const handleXLSX = (e) => {

        let wb = XLSX.utils.book_new()
        let ws = XLSX.utils.json_to_sheet(file)
        XLSX.utils.book_append_sheet(wb, ws, "MYSheet")

        XLSX.writeFile(wb, `CSX-NewFile.xlsx`);
        handleClose()
    }


    useEffect(() => {
        setmassege("Note: All the work will be on your browser. We don't save or see anything.")
        setbarType('info')
        setOpen(true)
    }, [])

    return (
        <>
            <Helmet>
                <title>CSV Converter. Excel Converter </title>
                <meta name='description' content='Convert Excel file to CSV file. Convert CSV file to Excel file. Convert XLSX file to CSV file. convert CSV file to XSLX file.' />
                <link rel='canonical' href='https://csxforfiles.com/Convert' />
            </Helmet>

            <div className='space-y-7 py-6'>

                <h1 className='text-4xl font-bold' >
                    Convert from CSV file to Excel file and convert from Excel file to csv file (XLSX file to CSV file and CSV file to XLSX file)
                </h1 >
                <div className='text-lg space-y-2 font-medium'>
                    <p>Convert Excel file to CSV file. Convert CSV file to Excel file. Convert XLSX file to CSV file. convert CSV file to XSLX file. </p>
                    <p>The convert operation is completely free and secure because all of the work will be done in your browser, and we will not see or save anything.</p>
                </div>

                {FileName &&
                    <div className='flex justify-center items-baseline text-lg font-semibold space-x-3 '>
                        <p>Uploaded File  Name: {FileName}</p>
                    </div>
                }

                <div className='flex justify-evenly flex-wrap'>

                    <button
                        onClick={() => hiddenFileInput.current.click()}
                        className='border-2 bg-white border-sky-600 rounded-lg hover:scale-105 transition duration-500 shadow-md m-3'>
                        <div className='w-[20rem] h-32 md:w-[27rem] md:h-44 flex justify-center items-center px-5 py-3 space-x-4 '>
                            <img src={uploadGIF} width='64' height='64' className='w-24 h-w-24' title='Upload GIF' alt='Upload GIF' />
                            <div>
                                <span className='text-xl font-bold'>Upload File</span>
                            </div>
                        </div>
                    </button>

                    <button
                        onClick={handleClickOpen}
                        className='border-2 bg-white border-sky-600 rounded-lg hover:scale-105 transition duration-500 shadow-md m-3'>
                        <div className='w-[20rem] h-32 md:w-[27rem] md:h-44 flex justify-center items-center px-5 py-3 space-x-4 '>
                            <img src={downloadGIF} width='64' height='64' className='w-24 h-w-24' title='Download GIF' alt='Download GIF' />
                            <div>
                                <span className='text-xl font-bold'>Converted and download your file</span>
                            </div>
                        </div>
                    </button>
                </div>
                {Loading && <LinearProgress />}


                <input
                    type="file"
                    accept=".csv , .xlsx"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    className='hidden'
                />


                {FileName &&
                    <CSVLink
                        ref={hiddenFileDownload}
                        filename={`CSX-NewFile.csv`}
                        headers={fileHeaders}
                        data={file}
                        className='hidden'>
                        Download as CSV file
                    </CSVLink>
                }

                <Dialog
                    open={openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    maxWidth='md'
                    fullWidth
                >
                    <DialogTitle className='flex justify-between items-center'>
                        {"How do you like to download the file !!"}
                        <button className='pb-2' onClick={handleClose}>
                            <CloseOutlinedIcon color='error' />
                        </button>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" className='flex justify-evenly items-center flex-wrap space-y-5 py-4 px-9 ' >
                            <Button onClick={handleCSV} size='large' variant='contained' sx={{ wordSpacing: 2 }}>Download as .CSV File</Button>
                            <Button onClick={handleXLSX} size='large' variant='contained' sx={{ wordSpacing: 2 }}>Download as .XLSX File</Button>
                        </DialogContentText>
                    </DialogContent>

                </Dialog>


                <SnackbarComp open={open} setOpen={setOpen} barType={barType} massege={massege} />
            </div>
        </>
    )
}
