import React from 'react'
import convertImg from '../img/home1.png'
import statiImg from '../img/home2.png'
import editImg from '../img/home3.png'
import displayImg from '../img/home4.png'
import autoImg from '../img/auto.png'
import downloadIMG from '../img/downloadHome.png'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Welcome To CSX For CSV files and Excel files</title>
        <meta name='description' content='CSX Home Page' />
        <link rel='canonical' href='https://csxforfiles.com/' />
      </Helmet>

      <div className='pb-9'>
        <article className='w-full h-3/4 py-3 bg-slate-200 rounded-xl space-y-9'>

          <div className='h-20 flex justify-center items-center bg-slate-300 rounded-lg mx-3 px-3 py-2'>
            <h1 className='text-3xl md:text-4xl font-bold px-3 py-2'>Welcome To CSX For Files</h1>
          </div>

          <div className='space-y-2 px-5'>
            <h2 className='text-2xl md:text-3xl font-bold'>Why Choose CSX For Files !?</h2>
            <p className=' leading-7 text-base font-medium pl-2'>
              Since we value your security and privacy. We handle all operations on your browser, this means that we can not see or save the file because all of your data stays on your device. Unlike other websites that handle your files on their servers and this puts your data in danger could access your data and store it. You can upload, open, edit, auto edit, view statistics, and convert your files without worrying about your data being compromised. Plus, you can access our website on any device at any time because it is responsive to all screen sizes. This is what makes us unique and trustworthy.
            </p>
          </div>

          <div className='space-y-8 px-5'>

            <div className='flex flex-wrap justify-start items-center lg:space-x-16'>
              <h2 className='text-2xl md:text-3xl font-bold'>
                What Can you do with your CSV file or Excel file (XLSX file) on our website !?
              </h2>

              <div className='flex justify-center'>
                <Link
                  to='/Operations'
                  className='border-2 border-slate-400 rounded-lg text-left px-16 py-4 text-xl font-semibold hover:scale-105 transition duration-500 shadow-md m-3'>
                  Let's Start !!
                </Link>
              </div>
            </div>


            <div className='lg:flex lg:flex-nowrap justify-start items-center space-x-4 space-y-4 '>
              <div className='basis-1/2 pl-2 space-x-4 space-y-2 '>

                <h3 className='text-lg font-bold capitalize'>1. Open CSV file, open Excel file (open xlsx file)</h3>
                <p className=' leading-7 text-base font-medium '>
                  You can open CSV file or open Excel file (open xlsx file) in tables on the web page. You can also search for any data within the tables using the search box. This feature is useful for viewing and analyzing your data online.
                </p>

              </div>
              <div className='basis-1/2 flex justify-center'>
                <img src={displayImg} width='160' height="160" className='w-40 h-40' alt='Display' title='Display' />
              </div>
            </div>

            <div className='lg:flex lg:flex-nowrap justify-start items-center space-x-4 space-y-4 '>
              <div className='basis-1/2 pl-2 space-x-4 space-y-2 '>

                <h3 className='text-lg font-bold capitalize'>2. File Editor</h3>
                <p className=' leading-7 text-base font-medium '>
                  This website have Excel editor (XLSX editor) and CSV editor. You can edit the cell data by double-clicking on the cells. You can delete columns or rows by selecting them and clicking on the delete button. You can search for any value within the tables using the search box and edit it or delete the corresponding row. You can rename the column names by clicking on the header cells and typing the new names. This feature is useful for modifying and managing your data online.
                </p>
              </div>

              <div className='basis-1/2 flex justify-center lg:justify-start lg:pl-52'>
                <img src={editImg} width='160' height="160" className='w-40 h-40' alt='Edit' title='Edit' />
              </div>
            </div>

            <div className='lg:flex lg:flex-nowrap justify-start items-center space-x-4 space-y-4 '>
              <div className='basis-1/2 pl-2 space-x-4 space-y-2 '>

                <h3 className='text-lg font-bold capitalize'>3. Auto edit file</h3>
                <p className=' leading-7 text-base font-medium '>
                  This website allows you to use auto edit feature to perform three operations on your CSV file or Excel file (XLSX file). The first operation is auto delete column, which checks the percentage of missing or invalid values in each column. If the percentage is higher than a specific threshold (that you enter), the column will be deleted. This feature is useful for cleaning your data and removing unnecessary columns. The second operation is to delete all rows that have empty values in them. This feature is useful for reducing the size of your data and removing irrelevant rows. The third operation is to fill in empty cells in a selected column with a value that you enter. This feature is useful for completing your data and avoiding errors.
                </p>
              </div>

              <div className='basis-1/2 flex justify-center'>
                <img src={autoImg} width='160' height="160" className='w-40 h-40' alt='Auto Edit' title='Auto Edit' />
              </div>
            </div>

            <div className='lg:flex lg:flex-nowrap justify-start items-center space-x-4 space-y-4 '>
              <div className='basis-1/2 pl-2 space-x-4 space-y-2 '>

                <h3 className='text-lg font-bold capitalize'>4. Excel statistics, csv statistics</h3>
                <p className=' leading-7 text-base font-medium '>
                  This website allows you to see statistics about your uploaded CSV file or Excel file (XLSX file) in various charts. The statistics include the number of rows and columns, the number of filled and empty cells and their percentages, and the summary of each column. For numerical columns, you can see the minimum, maximum, and average values for every column in a bar chart. For text columns, you can see the most repeated value in the column and some of the other values along with the number of times they appear. This feature is useful for visualizing and understanding your data better.
                </p>
              </div>

              <div className='basis-1/2 flex justify-center lg:justify-start lg:pl-52'>
                <img src={statiImg} width='160' height="160" className='w-40 h-40 ' alt='File Statistics' title='File Statistics' />
              </div>
            </div>

            <div className='lg:flex lg:flex-nowrap justify-start items-center space-x-4 space-y-4 '>
              <div className='basis-1/2 pl-2 space-x-4 space-y-2 '>

                <h3 className='text-lg font-bold capitalize'>5. Download your new CSV file or Excel file (XLSX file)</h3>
                <p className=' leading-7 text-base font-medium '>
                  You can download your new Excel file (XLSX file) or CSV file after editing it, and you can download it as a CSV file or Excel file (XLSX file).
                </p>
              </div>

              <div className='basis-1/2 flex justify-center'>
                <img src={downloadIMG} width='160' height="160" className='w-40 h-40 ' alt='File Statistics' title='File Statistics' />
              </div>
            </div>

            <div className='lg:flex lg:flex-nowrap justify-start items-center space-x-4 space-y-4 '>
              <div className='basis-1/2 pl-2 space-x-4 space-y-2 '>

                <h3 className='text-lg font-bold capitalize'>6. Convert file</h3>
                <p className=' leading-7 text-base font-medium '>
                  This website allows you to convert Excel file to CSV file and convert CSV file to Excel file (convert XLSX file to CSV file and convert CSV file to XSLX file). CSV stands for comma-separated values, which is a simple and widely used format for storing tabular data. XLSX stands for Excel spreadsheet, which is a more advanced and feature-rich format for working with data. You can upload your file in either format and choose the desired output format. The website will then convert your file and let you download it.
                </p>
              </div>

              <div className='basis-1/2 flex justify-center lg:justify-start lg:pl-52'>
                <img src={convertImg} width='160' height="160" className='w-40 h-40' alt='Convert Files' title='Convert Files' />
              </div>
            </div>

            <div className='flex justify-center'>
              <Link
                to='/Operations'
                className='border-2 border-slate-400 rounded-lg text-left px-16 py-4 text-xl font-semibold hover:scale-105 transition duration-500 shadow-md m-3'>
                Let's Start !!
              </Link>
            </div>
          </div>
        </article>
      </div>
    </>
  )
}
