import React, { useRef } from 'react'
import downloadFile from '../img/downloadFile.png'
import { CSVLink } from 'react-csv'
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as XLSX from "xlsx";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DownloadFile = ({ fileName, setOpen, setbarType, setmassege }) => {
    const hiddenFileDownload = useRef(null);
    const csv = useSelector((state) => state.file)
    let fileHeaders

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpen = (e) => {
        if (!fileName) {
            setbarType('error')
            setmassege('Upload file first')
            setOpen(true)
        }
        else {
            fileHeaders = Object.keys(csv[0]);
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };


    const handleCSV = (e) => {
        hiddenFileDownload.current.link.click()
        handleClose()

    }

    const handleXLSX = (e) => {
        let wb = XLSX.utils.book_new()
        let ws = XLSX.utils.json_to_sheet(csv)
        XLSX.utils.book_append_sheet(wb, ws, "MYSheet")

        XLSX.writeFile(wb, `CSX-NewFile.xlsx`);
        handleClose()
    }

    return (
        <div>
            <button
                onClick={(e) => {
                    handleClickOpen()
                }}
                className={`border-2 border-sky-600 rounded-lg text-left hover:scale-105 transition duration-500 shadow-md m-3 ${fileName ? '' : 'cursor-not-allowed'}`}>
                <div className='w-72 h-32 flex justify-start items-center px-5 py-3 space-x-4 '>
                    <img src={downloadFile} width='64' height='64' className='w-16 h-16' alt='Auto' />
                    <div>
                        <span className='text-xl font-bold'>Download file</span>
                    </div>
                </div>
            </button>

            {fileName &&
                <CSVLink
                    ref={hiddenFileDownload}
                    filename={`CSX-NewFile.csv`}
                    headers={fileHeaders}
                    data={csv}
                    className='hidden'>
                    Download as CSV file
                </CSVLink>
            }

            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth='md'
                fullWidth
            >
                <DialogTitle className='flex justify-between items-center'>
                    {"How do  you like to download the file !!"}
                    <button className='pb-2' onClick={handleClose}>
                        <CloseOutlinedIcon color='error' />
                    </button>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" className='flex justify-evenly items-center flex-wrap space-y-5 py-4 px-9 ' >
                        <Button onClick={handleCSV} size='large' variant='contained' sx={{ wordSpacing: 2 }}>Download as .CSV File</Button>
                        <Button onClick={handleXLSX} size='large' variant='contained' sx={{ wordSpacing: 2 }}>Download as .XLSX File</Button>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}
