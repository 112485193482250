import React, { useState } from 'react'
import deleteRow from '../../img/deleteRow.png'
import { useDispatch } from 'react-redux';
import { adddata } from '../../redux/DataSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export default function AutoDeleteRows({ tempCSV, setTempCSV, setOpen, setbarType, setmassege }) {
    const dispatch = useDispatch()
    const [opendialog, setOpendialog] = useState(false);

    const handleClose = () => {
        setOpendialog(false);
    };

    const handleDeleteRows = () => {
        const deletedCells = tempCSV.filter(obj => {
            const values = Object.values(obj);
            return values.every(val =>
                val !== '' && val !== null && val !== undefined
            );
        });
        let counter = tempCSV.length - deletedCells.length
        setTempCSV(deletedCells)
        dispatch(adddata(deletedCells))

        setOpendialog(false)
        setbarType('info')
        setmassege(`${counter} rows was deleted`)
        setOpen(true)
    }

    return (
        <>
            <button
                onClick={e => setOpendialog(true)}
                className='border-2 border-sky-600 rounded-lg text-left hover:scale-105 transition duration-500 shadow-md m-3'>
                <div className='w-72 h-32 flex justify-start items-center px-5 py-3 space-x-4 '>
                    <img src={deleteRow} width='64' height='64' className='w-16 h-16' alt='Auto delete empty rows' />
                    <div>
                        <span className='text-xl font-bold'>Delete rows</span>
                        <br />
                        <span className='text-xs'>Delete all rows that have empty cell in it </span>
                    </div>
                </div>
            </button>

            <Dialog
                open={opendialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {"Alert"}
                </DialogTitle>
                <DialogContent >
                    Are you sure to delete all empty rows !
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>Cancel</Button>
                    <Button onClick={handleDeleteRows} color="error" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    )
}
