import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ChartLine } from '../Charts/Charts';

export default function NumericalColumnstatistics() {

  const [numberColumnsNames, setnumberColumnsNames] = useState([])
  const [statsForNumberColumns, setstatsForNumberColumns] = useState([])


  const csv = useSelector((state) => state.file)
  let statsForStringColumns = []



  const getStatsForNumberColumns = (key) => {
    let min = Infinity;
    let max = -Infinity;
    let sum = 0;

    for (let i = 0; i < csv.length; i++) {
      let value = csv[i][key];
      min = Math.min(min, value);
      max = Math.max(max, value);
      sum += value;
    }

    return [
      min,
      sum / csv.length,
      max,
    ];
  }




  useEffect(() => {

    let x = Object.keys(csv[0])
    Object.values(csv[0]).map((value, index) => {
      let columnName = x[index]
      if (typeof (value) === 'number') {
        setstatsForNumberColumns(prev => [...prev, { [columnName]: getStatsForNumberColumns(columnName) }])
        setnumberColumnsNames(prev => [...prev, columnName])
      }

      else if (typeof (value) === 'string') {

      }

      else {
        let secondtry = csv[2][x[index]]

        if (typeof (secondtry) === 'number') {
          setstatsForNumberColumns(prev => [...prev, { [columnName]: getStatsForNumberColumns(columnName) }])
          setnumberColumnsNames(prev => [...prev, columnName])

        }
        else if (typeof (secondtry) === 'string') {

        }
        else {

        }
      }
    })
  }, [])


  return (
    <div className='space-y-7'>
      <h2 className='font-semibold text-2xl' id='NumericalColumns'>Numerical Columns Statistics</h2>
      {statsForNumberColumns != 0 ?
        <>
          <div className='flex items-center justify-center pt-4'>

            <table className='max-h-[80vh] w-fit max-w-[100%] text-left overflow-scroll block border-b font-[0.9em] font-sans shadow-md rounded-md'>
              <thead className=''>
                <tr className='text-white'>
                  <th className='sticky top-0 py-3 px-6 bg-[#1976d2]'></th>

                  <th className='sticky top-0 py-3 px-6 bg-[#1976d2]'>Min</th>
                  <th className='sticky top-0 py-3 px-6 bg-[#1976d2]'>Avg</th>
                  <th className='sticky top-0 py-3 px-6 bg-[#1976d2]'>Max</th>

                </tr>
              </thead>

              <tbody>
                {statsForNumberColumns.map((value, index) => (
                  Object.values(statsForNumberColumns[index]).map((value2, index2) => (
                    index % 2 == 0 ?
                      <tr key={index2} className='' >
                        <td className='px-6 py-3 bg-white font-bold' key={index2 + 1} > {numberColumnsNames[index]}:</td>
                        <td className='px-6 py-3 bg-white' key={index2 + 2} > {value2[index2].toFixed(3)}</td>
                        <td className='px-6 py-3 bg-white' key={index2 + 3} > {value2[index2 + 1].toFixed(3)}</td>
                        <td className='px-6 py-3 bg-white' key={index2 + 4} > {value2[index2 + 2].toFixed(3)}</td>
                      </tr>
                      :
                      <tr key={index2} className='' >
                        <td className='px-6 py-3 bg-slate-100 font-bold' key={index2 + 1} > {numberColumnsNames[index]}:</td>
                        <td className='px-6 py-3 bg-slate-100' key={index2 + 2} > {value2[index2].toFixed(3)}</td>
                        <td className='px-6 py-3 bg-slate-100' key={index2 + 3} > {value2[index2 + 1].toFixed(3)}</td>
                        <td className='px-6 py-3 bg-slate-100' key={index2 + 4} > {value2[index2 + 2].toFixed(3)}</td>
                      </tr>
                  )))
                )}
              </tbody>
            </table>
          </div>

          <div className='grid sm:grid-cols-1 lg:grid-cols-2'>
            {statsForNumberColumns.map((value, index) => (
              <div key={index} className=' border py-2 px-4'>
                <h3 className='font-semibold' key={index}>{numberColumnsNames[index]}</h3>
                {Object.values(statsForNumberColumns[index]).map((value2, index2) => (
                  <div className='flex justify-center'>
                    <ChartLine Y={value2} X={['MIN', 'AVG', 'MAX']} key={index2} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
        :
        <p className='px-5 '>There is No numerical columns in your file</p>
      }

    </div >
  )
}
