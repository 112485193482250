import React, { useRef, useState } from 'react'
import Papa from 'papaparse'
import uploadFileIMG from '../img/upoladfile.png'
import { useDispatch, useSelector } from 'react-redux';
import { addNewfileName, adddata, deleteFileName, deletedata } from '../redux/DataSlice';
import { SnackbarComp } from './snackbar';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Tooltip } from '@mui/material';
import * as XLSX from "xlsx";
import LinearProgress from '@mui/material/LinearProgress';

export default function InputCSV() {
    const hiddenFileInput = useRef(null);
    const filename = useSelector((state) => state.fileName)
    const [fileName, setFileName] = useState(filename)
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [barType, setbarType] = useState('success')
    const [massege, setmassege] = useState('')


    const [Loading, setLoading] = useState(false)

    const handleChange = (event) => {
        setLoading(true)
        let file = event.target.files[0];
        let lastThree = file.name.slice(-3);
        if (lastThree === 'csv') {
            handleChangeCSV(event)
        }
        else if (lastThree === 'lsx') {
            handleChangeXLSX(event)
        }

    }

    const handleChangeCSV = event => {
        let file = event.target.files[0];
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: function (results) {
                setLoading(false)
                let x = results.data
                x.pop()
                dispatch(adddata(x))
                dispatch(addNewfileName(file.name))
                setFileName(file.name)
                setbarType('success')
                setmassege('File uploaded successful')
                setOpen(true)
            }
        });
    };

    const handleChangeXLSX = async (e) => {
        let file = e.target.files[0];
        const data = await file.arrayBuffer()
        const workbook = XLSX.read(data)
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const json_data = XLSX.utils.sheet_to_json(worksheet)
        setLoading(false)
        let x = json_data
        x.pop()
        dispatch(adddata(x))
        dispatch(addNewfileName(file.name))
        setFileName(file.name)
        setbarType('success')
        setmassege('File uploaded successful')
        setOpen(true)

    };

    const removeData = () => {
        dispatch(deletedata())
        dispatch(deleteFileName())
        window.location.reload()
    }

    return (
        <>
            <div className='w-full h-full flex justify-center items-center'>
                <button
                    onClick={() => hiddenFileInput.current.click()}
                    className='border-2 border-sky-600 rounded-lg text-left hover:scale-105 transition duration-500 shadow-md m-3'>
                    <div className='w-72 h-32 md:w-96 md:h-36 flex justify-center items-center px-5 py-3 space-x-4'>
                        <img src={uploadFileIMG} width='64' height='64' className='w-16 h-16' title='Upload CSV or XLSX(Excel) file' alt='Upload CSV or XLSX(Excel) file' />
                        <div>
                            <span className='text-xl font-bold'>Upload File</span>
                        </div>
                    </div>
                </button>

                <input
                    type="file"
                    accept=".csv , .xlsx"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    className='hidden'
                />

            </div>
            {Loading &&
                <LinearProgress />
            }

            {fileName &&
                <div className='flex justify-center items-center text-center text-lg space-x-5'>
                    <p className='font-semibold'>Uploaded File  Name: <span className='font-bold'>{fileName}</span></p>
                    <button className='flex items-center p-1 rounded-full hover:bg-slate-200 ' onClick={() => removeData()}>
                        <span className='sr-only'>Remove File</span>
                        <Tooltip title='Remove File'>
                            <RemoveCircleOutlineIcon color='error' />
                        </Tooltip>
                    </button>
                </div>
            }

            <SnackbarComp open={open} setOpen={setOpen} barType={barType} massege={massege} />
        </>
    )
}
