import { createSlice } from '@reduxjs/toolkit'

export const DataSlice = createSlice({
    name: 'fileData',
    initialState: {
        file: [{ id: 1 }],
        fileName: null,
    },
    reducers: {
        adddata: (state, action) => {
            state.file = action.payload
        },
        deletedata: (state, action) => {
            state.file = []
        },

        addNewfileName: (state, action) => {
            state.fileName = action.payload
        },
        deleteFileName: (state, action) => {
            state.fileName = null
        },
    },
})

export const { adddata, deletedata, addNewfileName, deleteFileName } = DataSlice.actions

export default DataSlice.reducer