import { Alert, Snackbar } from '@mui/material'
import React from 'react'

export const SnackbarComp = ({ open, setOpen, barType, massege }) => {
    // error
    // success

    const handleClose = (event, reason) => {
        setOpen(false);
    };

    return (
        <div>
            <Snackbar open={open} autoHideDuration={4500} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={barType}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {massege}
                </Alert>
            </Snackbar>
        </div>
    )
}
