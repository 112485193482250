import React from 'react'
import { Helmet } from 'react-helmet-async'

export default function PrivacyPolicy() {
    return (
        <>
            <Helmet>
                <title>CSX for files - Privacy Policy</title>
                <meta name='description' content='This page have Privacy Policy for csxforfiles.com' />
                <link rel='canonical' href='https://csxforfiles.com/PrivacyPolicy' />
            </Helmet>
            <div className='space-y-10'>
                <p className='text-lg'>Last Updated: 24 February 2024</p>
                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>Introduction</h2>
                    <p className='text-xl font-medium pl-5'>
                        Welcome to csxforfiles.com! This privacy policy explains how we handle any information collected from visitors to our website.
                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>Information We Collect</h2>
                    <p className='text-xl font-medium pl-5'>
                        We do not collect any personal data directly from users this page is made from pure React.js (Frontend Library) we don't have a database. However, we use Hostinger Analytics, Google Search Console, and Bing Webmaster Tools to gather anonymous data about website traffic and user behavior. This data helps us improve our services.
                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        Hostinger Analytics
                    </h2>
                    <p className='text-xl font-medium pl-5'>
                        Hostinger Analytics collects non-personal information such as IP addresses, browser types, and device details.
                        This data is used solely for statistical purposes and does not identify individual users.
                        For more information

                        <a href='https://www.hostinger.com/legal/privacy-policy' target='_blank' className='underline pl-2'>
                            Hosinger privacy policy
                        </a>
                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        Google Search Console
                    </h2>
                    <p className='text-xl font-medium pl-5'>
                        Google Search Console provides insights into how your website appears in Google search results.
                        It does not collect personal data from users.
                        For more information

                        <a href='https://policies.google.com/terms?hl=en' target='_blank' className='underline pl-2'>
                            Google privacy policy
                        </a>
                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        Bing Webmaster Tools
                    </h2>
                    <p className='text-xl font-medium pl-5'>
                        Bing Webmaster Tools offers website diagnostics and performance insights.
                        Like Google Search Console, it does not collect personal information.
                        For more information

                        <a href='https://privacy.microsoft.com/en-us/privacystatement' target='_blank' className='underline pl-2'>
                            Microsoft privacy policy
                        </a>
                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        Cookies
                    </h2>
                    <p className='text-xl font-medium pl-5'>
                        Our website may use cookies to enhance user experience. Cookies are small text files stored on your device.
                        These cookies do not contain personal information and are used for functionality and analytics.
                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        Third-Party Services
                    </h2>
                    <p className='text-xl font-medium pl-5'>
                        We use Hostinger Analytics, Google Search Console, and Bing Webmaster Tools.
                        Please review their respective privacy policies for more details on their data practices.
                        <br />
                        <a href='https://www.hostinger.com/legal/privacy-policy' target='_blank' className='underline pl-4'>
                            Hosinger privacy policy
                        </a>
                        <a href='https://policies.google.com/terms?hl=en' target='_blank' className='underline pl-4'>
                            Google privacy policy
                        </a>
                        <a href='https://privacy.microsoft.com/en-us/privacystatement' target='_blank' className='underline pl-4'>
                            Microsoft privacy policy
                        </a>

                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        Changes to this Policy
                    </h2>
                    <p className='text-xl font-medium pl-5'>
                        We reserve the right to update this privacy policy as needed.
                        Any changes will be posted on this page.
                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        Contact Us
                    </h2>
                    <p className='text-xl font-medium pl-5'>
                        If you have any questions or concerns regarding our privacy policy, please contact us at
                        <a className='underline pl-2' href="mailto: admin@csxforfiles.com">admin@csxforfiles.com</a>.
                    </p>
                </div>
            </div>
        </>
    )
}
