import React, { useState } from 'react'
import { Table } from '../compnents/Table'
import { useSelector } from 'react-redux'
import { Pagination, Tooltip } from '@mui/material'
import { SnackbarComp } from '../compnents/snackbar'
import AutoDeleteRows from '../compnents/Auto_Operations/AutoDeleteRows'
import AutoDeleteColumns from '../compnents/Auto_Operations/AutoDeleteColumns'
import AutoFillEmptyCells from '../compnents/Auto_Operations/AutoFillEmptyCells'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Helmet } from 'react-helmet-async'

export default function AutoEditDataSet() {

    const fileName = useSelector((state) => state.fileName)

    const csv = useSelector((state) => state.file)
    const [tempCSV, setTempCSV] = useState(useSelector((state) => state.file))

    //snackbar
    const [open, setOpen] = useState(false)
    const [barType, setbarType] = useState('success')
    const [massege, setmassege] = useState('')

    //bagination
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 100;
    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = tempCSV.slice(firstPostIndex, lastPostIndex);
    let pages = [];
    for (let i = 1; i <= Math.ceil(csv.length / postsPerPage); i++) {
        pages.push(i);
    }

    return (
        <>
            <Helmet>
                <title>Auto CSV or Excel File Edit</title>
                <meta name='description' content='Edit file by auto operations. Delete columns that have hight percentage of empty cells and delete rows that have empty cells and fill all Empty Cells with specific value.' />
                <link rel='canonical' href='https://csxforfiles.com/Auto_Edit' />
            </Helmet>

            <div className='absolute left-10'>
                <Tooltip title="Back">
                    <Link to='/Operations'>
                        <ArrowBackIcon fontSize='medium' />
                    </Link>
                </Tooltip>
            </div>


            <div className='flex items-end justify-center text-lg p-2 '>
                <h1 className='text-4xl font-semibold'>Auto Edit {fileName} File</h1>
            </div>

            <div className='flex justify-evenly py-12 flex-wrap'>
                <AutoDeleteColumns tempCSV={tempCSV} setTempCSV={setTempCSV} setOpen={setOpen} setmassege={setmassege} setbarType={setbarType} />
                <AutoDeleteRows tempCSV={tempCSV} setTempCSV={setTempCSV} setOpen={setOpen} setmassege={setmassege} setbarType={setbarType} />
                <AutoFillEmptyCells tempCSV={tempCSV} setTempCSV={setTempCSV} setOpen={setOpen} setmassege={setmassege} setbarType={setbarType} />
            </div>

            <Table colNames={tempCSV} rows={currentPosts} />
            <div className='flex h-28 w-full justify-center items-center '>
                <Pagination
                    onChange={(event, page) => { setCurrentPage(page) }}
                    count={pages.length}
                    color="primary"
                    showFirstButton
                    showLastButton
                    size='large' />
            </div>

            <SnackbarComp open={open} setOpen={setOpen} barType={barType} massege={massege} />

        </>
    )
}
