import React, { useState } from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { PreprocesTable, SearchTable } from './Table';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';

import { adddata } from '../redux/DataSlice';

export default function Preprocessing({ tempCSV, setTempCSV }) {
  const fileName = useSelector((state) => state.fileName)

  const [EndofShowenCSV, setEndOfShowenCSV] = useState(100);
  const [TempForShow, setTempForShow] = useState(tempCSV.slice(0, EndofShowenCSV));
  const dispatch = useDispatch()


  const [searchResult, setSearchResult] = useState([]);
  const [indResult, setIndResult] = useState([]);
  const [EndofShowenSearch, setEndOfShowenSearch] = useState(5);
  const [TempForSearchShow, setTempForSearchShow] = useState([]);
  const [TempForIndexSearchShow, setTempForIndexSearchShow] = useState([]);

  const [viewcsv, setviewcsv] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [columns, setColomns] = useState(Object.keys(tempCSV[0]));
  const [newColumnNames, setNewColumnNames] = useState([]);
  const [viewSearch, setViewSearch] = useState(false);

  //dialog and info or rename
  const [openRename, setOpenRename] = useState(false);
  const [OldColumnName, setOldColumnName] = useState('');
  const [IndexOldColumnName, setIndexOldColumnName] = useState('');

  //dialog and info or rename
  const [openEditCell, setOpenEditCell] = useState(false);
  const [targetColumn, settargetColumn] = useState('');
  const [rowNumber, setrowNumber] = useState(0);
  const [oldValueEditCell, setoldValueEditCell] = useState('');
  const [newValueEditCell, setnewValueEditCell] = useState('');


  const handleScrollInTable = (e) => {
    let nerlyEqual = Math.round(e.target.scrollHeight - e.target.scrollTop) - e.target.clientHeight;
    // const bottom = Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;

    if (nerlyEqual < 150) {
      if (TempForShow.length !== tempCSV.length) {

        setEndOfShowenCSV(EndofShowenCSV + 100)
        let x = EndofShowenCSV
        setTempForShow(tempCSV.slice(0, x))
      }
    }
  }

  const handleRenameColumn = (colName, index) => {
    setviewcsv(false)
    setSearchResult([])
    setViewSearch(false)
    setEndOfShowenCSV(100)

    let newnameC = newColumnNames[index]

    let newArray = tempCSV.map(obj => {
      let newObj = { ...obj };
      newObj[newnameC] = newObj[colName];
      delete newObj[colName];
      return newObj;
    });

    setTempForShow(newArray.slice(0, EndofShowenCSV));
    setTempCSV(newArray)
    dispatch(adddata(newArray))
    setviewcsv(true)
  }

  const handleSearch = () => {
    setviewcsv(false)
    setViewSearch(false)
    let isnum = /^\d+$/.test(searchValue);
    let checkedValue = searchValue
    if (isnum) {
      checkedValue = parseInt(searchValue)
    }

    setSearchResult([]);
    setIndResult([]);
    setTempForSearchShow([])
    setTempForIndexSearchShow([])

    let x = 0
    for (let i = 0; i < columns.length; i++) {
      tempCSV.filter((row, index) => {
        if (row[columns[i]] === checkedValue) {
          setSearchResult(prev => ([...prev, row]));
          setIndResult(prev => ([...prev, index]))
          if (x < 50) {
            setTempForSearchShow(prev => ([...prev, row]))
            setTempForIndexSearchShow(prev => ([...prev, index]))
            x++
          }
        }
      })
    }
    setViewSearch(true)
  }

  const handleScrollInSearchTable = (e) => {
    let nerlyEqual = Math.round(e.target.scrollHeight - e.target.scrollTop) - e.target.clientHeight;
    // const bottom = Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;

    if (nerlyEqual < 150) {
      if (TempForSearchShow.length !== searchResult.length) {

        setEndOfShowenSearch(EndofShowenSearch + 100)
        let x = EndofShowenCSV
        setTempForSearchShow(searchResult.slice(0, x))
        setTempForIndexSearchShow(indResult.slice(0, x))
      }
    }
  }

  const handleDeleteRowformResulr = (index, indexRow, row) => {
    setSearchResult((prevData) => prevData.filter((_, i) => i !== index));
    const newData = tempCSV.filter((_, i) => i !== indexRow)

    const updatedArray = indResult
      .filter(value => value !== indexRow)
      .map(value => (value > indexRow ? value - 1 : value));

    setTempForSearchShow((prevData) => prevData.filter((_, i) => i !== index));
    setTempForIndexSearchShow(updatedArray.slice(0, 50))

    setIndResult(updatedArray)
    setTempCSV(newData);

    dispatch(adddata(newData))
  };

  const handleDeleteRow = (index, row) => {
    const newData = tempCSV.filter((_, i) => i !== index)
    setTempCSV(newData)
    setTempForShow(newData.slice(0, EndofShowenCSV))
    setSearchResult([]);
    dispatch(adddata(newData))

  };


  const handleDeleteColumn = (column) => {
    const newData = tempCSV.map((row) => {
      const newRow = { ...row };
      delete newRow[column];
      return newRow;
    })

    setTempCSV(newData);
    setTempForShow(newData.slice(0, EndofShowenCSV));
    dispatch(adddata(newData))
    setSearchResult([]);
  };

  const handleEditColumnName = (oldName, index) => {
    setOpenRename(true)
    setOldColumnName(oldName)
    setIndexOldColumnName(index)
  }

  const handleClickOnCell = (rowNumber, columnNumber) => {
    const allColumnsNames = Object.keys(tempCSV[0])
    const targetColumn = allColumnsNames[columnNumber]

    settargetColumn(targetColumn)
    setrowNumber(rowNumber)
    setoldValueEditCell(tempCSV[rowNumber][targetColumn])

    setOpenEditCell(true)
  }

  const handleEditCell = () => {
    let isnum = /^\d+$/.test(newValueEditCell);
    let checkedValue = newValueEditCell
    if (isnum) {
      checkedValue = parseInt(newValueEditCell)
    }

    let newdata = tempCSV.map((row, index) => {
      if (index === rowNumber) {
        return { ...row, [targetColumn]: checkedValue }
      }
      else {
        return { ...row }
      }
    })

    if (searchResult.length !== 0) {
      setSearchResult([])
      setviewcsv(true)
    }

    setTempForShow(newdata.slice(0, EndofShowenCSV))
    setTempCSV([...newdata]);
    dispatch(adddata(newdata))

  }


  const handleClose = () => {
    setOpenRename(false);
    setOpenEditCell(false)
  };


  return (
    <>
      {/* Back button */}
      <div className='absolute left-10'>
        <Tooltip title="Back">
          <Link to='/Operations'>
            <ArrowBackIcon fontSize='medium' />
          </Link>
        </Tooltip>
      </div>

      <div className='flex items-end justify-center text-lg p-2 '>
        <h1 className='text-4xl font-semibold'>Editing {fileName} File</h1>
      </div>

      {/* Searsh form and view/hide button*/}
      <div className='flex flex-wrap items-center justify-between pb-4 pt-9'>
        <search className='flex items-center '>
          <div className='border border-stone-400 w-fit rounded-lg shadow-lg'>
            <input
              className='h-11 p-2 bg-white bg-opacity-10 rounded-lg focus:outline-none'
              type="text"
              placeholder='Search for any value '
              onChange={e => setSearchValue(e.target.value)} />
            <button
              className='border-l px-3'
              onClick={handleSearch}>
              <SearchOutlinedIcon fontSize='medium' className='mr-1 mb-[2px] text-slate-700' />
              <span className='text-slate-700'>Search</span>
            </button>
          </div>
          {viewSearch &&
            <p className='px-4 text-center text-xl font-bold'>Found <span className=' bg-[#1976d2] py-1 px-2 text-white rounded'>{searchResult.length}</span> Times</p>
          }
        </search>

        {/* show / hide Table */}
        <div className='flex justify-center items-center p-2'>
          <button
            className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden  rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white"
            onClick={() => {
              setSearchResult([])
              setViewSearch(false)
              setEndOfShowenCSV(100)
              setTempForShow(tempCSV.slice(0, EndofShowenCSV));
              setviewcsv(!viewcsv)
            }}>
            {viewcsv ?
              <span className="flex px-5 py-2.5 space-x-2 transition-all ease-in duration-75 bg-[#f9f7f7] rounded-md group-hover:bg-opacity-0">
                <VisibilityOffIcon />
                <p>Hide File</p>
              </span>
              :
              <span className="flex px-5 py-2.5 space-x-2 transition-all ease-in duration-75 bg-[#f9f7f7] rounded-md group-hover:bg-opacity-0">
                <RemoveRedEyeOutlinedIcon />
                <p>View File</p>
              </span>
            }
          </button>
        </div>

      </div >

      {
        searchResult.length !== 0 &&
        <SearchTable colNames={tempCSV} rows={TempForSearchShow} indResult={TempForIndexSearchShow} handleScrollInSearchTable={handleScrollInSearchTable} handleDeleteRowformResulr={handleDeleteRowformResulr} handleClickOnCell={handleClickOnCell} />
      }

      {
        viewcsv &&
        <PreprocesTable colNames={tempCSV} rows={TempForShow} handleScrollInTable={handleScrollInTable} handleDeleteRow={handleDeleteRow} handleDeleteColumn={handleDeleteColumn} handleEditColumnName={handleEditColumnName} handleClickOnCell={handleClickOnCell} />
      }
      {/* Rename Column Dialog  */}
      <Dialog
        open={openRename}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth={true}
      >
        <DialogTitle>Rename "{OldColumnName}" column</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Rename "{OldColumnName}" column
          </DialogContentText> */}
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="New Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              let newArr = Object.keys(tempCSV[0])
              newArr[IndexOldColumnName] = e.target.value
              setNewColumnNames(newArr)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="button"
            variant='contained'
            onClick={() => {
              handleRenameColumn(OldColumnName, IndexOldColumnName)
              handleClose()
            }}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Cells Dialog */}
      <Dialog
        open={openEditCell}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth={true}
      >
        <DialogTitle>Edit "{oldValueEditCell}" in {targetColumn}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Rename "{OldColumnName}" column
          </DialogContentText> */}
          <TextField
            autoFocus
            required
            margin="dense"
            id="newvalue"
            label="New Value"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setnewValueEditCell(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="button"
            variant='contained'
            onClick={() => {
              handleEditCell()
              handleClose()
            }}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>


    </>
  )
}
