import "./App.css";
import Navbar from "./compnents/Navbar";
import MainPage from "./pages/MainPage";
import { Route, Routes } from "react-router-dom";
import ViewDataset from "./pages/ViewDataset";
import EditDataSet from "./pages/EditDataSet";
import AutoEditDataSet from "./pages/AutoEditDataSet";
import StatisticsPage from "./pages/StatisticsPage";
import { useSelector } from "react-redux";
import { ProtectRoute } from "./pages/ProtectRoute";
import About from "./pages/About";
import Convert from "./pages/Convert";
import Error404 from "./pages/Error404";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./compnents/Footer";
import Disclaimer from "./pages/Disclaimer";
import MainBlogPage from "./pages/blogs/MainBlogPage";
import CSV_Blog from "./pages/blogs/CSV_Blog";
import XLSX_Blog from "./pages/blogs/XLSX_Blog";

function App() {
    const fileName = useSelector((state) => state.fileName)

    return (
        <>
            <Navbar />
            <div
                className="flex w-full min-h-[90dvh] justify-center bg-[#F9F7F7] ">
                <div className="w-[90%] pt-5">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/Operations" element={<MainPage />} />
                        <Route path="/View_File" element={fileName ? <ViewDataset /> : <ProtectRoute />} />
                        <Route path="/Edit_File" element={fileName ? <EditDataSet /> : <ProtectRoute />} />
                        <Route path="/Auto_Edit" element={fileName ? <AutoEditDataSet /> : <ProtectRoute />} />
                        <Route path="/File_Statistics" element={fileName ? <StatisticsPage /> : <ProtectRoute />} />
                        <Route path="/Convert" element={<Convert />} />
                        <Route path="/About" element={<About />} />
                        
                        <Route path="/Blogs" element={<MainBlogPage />} />
                        <Route path="/Blogs/CSV_File_Blog" element={<CSV_Blog />} />
                        <Route path="/Blogs/XLSX_File_Blog" element={<XLSX_Blog />} />

                        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                        <Route path="/Disclaimer" element={<Disclaimer />} />
                        <Route path="*" element={<Error404 />} />

                    </Routes>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default App;
