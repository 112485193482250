import React, { forwardRef, useState } from 'react'
import deleteColumn from '../../img/deleteColumn.png'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slide, Slider, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { adddata } from '../../redux/DataSlice';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AutoDeleteColumns({ tempCSV, setTempCSV, setOpen, setbarType, setmassege }) {

    const [openDialog, setOpenDialog] = useState(false);
    const [ColumnToDelete, setColumnToDelete] = useState('');
    const [PresentToDelete, setPresentToDelete] = useState(5);
    const dispatch = useDispatch()

    const handleClose = () => {
        setOpenDialog(false);
    };

    const [temptodeletecolumn, settemptodeletecolumn] = useState([]);
    const handleDeletecolumns = (key, inputpersent) => {

        if (key === '') {
            setmassege('Chosse Column')
            setbarType('error')
            setOpen(true)
        }

        else if (key === 'All columns') {
            Object.keys(tempCSV[0]).map(keys => {
                let counter = 0
                tempCSV.map((obj) => {
                    if (obj[keys] === '' || obj[keys] === null || obj[keys] === undefined) {
                        counter++
                    }
                });

                let emptyPersent = (counter * 100 / tempCSV.length).toFixed(3)
                if (emptyPersent > inputpersent) {
                    const newCSVData = tempCSV.map(obj => {
                        const newObj = { ...obj };
                        delete newObj[keys];
                        return newObj;
                    });
                    setTempCSV(newCSVData)
                    dispatch(adddata(newCSVData))

                    setmassege(`${keys} column was ${emptyPersent}% empty and has been deleted`)
                    setbarType('success')
                    setOpen(true)
                }
            })
            handleClose()
        }

        else {
            let counter = 0
            tempCSV.map((obj) => {
                if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
                    counter++
                }
            });

            let emptyPersent = (counter * 100 / tempCSV.length).toFixed(3)

            if (emptyPersent > inputpersent) {
                const tempCSV2 = [...tempCSV]; // make a copy of the state
                const newCSVData = tempCSV2.map(obj => {
                    const newObj = { ...obj }; // make a copy of the object
                    delete newObj[key]; // delete the key
                    return newObj;
                });
                setTempCSV(newCSVData)
                dispatch(adddata(newCSVData))

                setmassege(`${key} column was ${emptyPersent}% empty and has been deleted`)
                setbarType('success')
                setOpen(true)
                handleClose()
            }
            else {
                setmassege(`${key} is ${emptyPersent}% empty`)
                setbarType('info')
                setOpen(true)
            }

        }
    };

    return (
        <div>
            <button
                onClick={e => setOpenDialog(true)}
                className='border-2 border-sky-600 rounded-lg text-left hover:scale-105 transition duration-500 shadow-md m-3'>
                <div className='w-72 h-32 flex justify-start items-center px-5 py-3 space-x-4 '>
                    <img src={deleteColumn} width='64' height='64' className='w-16 h-16' alt='Auto delete columns' />
                    <div>
                        <span className='text-xl font-bold'>Delete columns</span>
                        <br />
                        <span className='text-xs'>Delete column/s that have a X% of empty cells</span>
                    </div>
                </div>
            </button>


            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>{"Delete columns that have empty cells"}</DialogTitle>
                <DialogContent className='space-y-8 '>
                    <FormControl>
                        <Typography id="demo-row-radio-buttons-group-label">Choose column:</Typography>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            // value={ColumnToDelete}
                            className='pl-2'
                            onChange={(e) => setColumnToDelete(e.target.value)}
                        >
                            {/* <FormControlLabel className='underline' value='All columns' control={<Radio />} label='All columns' /> */}

                            {Object.keys(tempCSV[0]).map(key => (
                                <FormControlLabel value={key} control={<Radio />} label={key} key={key} />
                            ))}

                        </RadioGroup>
                    </FormControl>

                    <Box sx={{ width: "full" }}>
                        <Typography >
                            Choose percentage:
                        </Typography>
                        <Box className='flex justify-center w-[75%] pl-2'>
                            <Slider
                                size="medium"
                                defaultValue={5}
                                aria-label="Small"
                                valueLabelDisplay="auto"
                                onChange={(e) => setPresentToDelete(e.target.value)}
                                marks={
                                    [{
                                        value: 0,
                                        label: '0%',
                                    },
                                    {
                                        value: 50,
                                        label: '50%'
                                    },
                                    {
                                        value: 100,
                                        label: '100%'
                                    }
                                    ]
                                }
                            />
                        </Box>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>cancel</Button>
                    <Button onClick={() => handleDeletecolumns(ColumnToDelete, PresentToDelete)} color='error' variant="contained">Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
