import { BarChart, LineChart, PieChart } from '@mui/x-charts'
import React from 'react'

function ChartPie({ data }) {
    return (
        <PieChart
            series={[
                {
                    data: data,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 1,
                    cornerRadius: 8,
                    startAngle: 0,
                    endAngle: 360,
                    cx: 150,
                    cy: 150,
                }
            ]}
            width={400}
            height={300}
        />
    )
}


function ChartBar({ X, Y }) {
    return (
        <BarChart
            series={[{ data: Y }]}
            xAxis={[{ scaleType: 'band', data: X }]}
            height={300}
            width={400}
        />
    )
}


function ChartLine({ X, Y }) {
    return (
        <LineChart
            xAxis={[{ scaleType: 'band', data: X }]}
            series={[{ data: Y }]}
            height={300}
            width={400}
        />
    )
}


export { ChartPie, ChartBar, ChartLine }