import React from 'react'
import { Helmet } from 'react-helmet-async'

export default function Disclaimer() {
    return (
        <>
            <Helmet>
                <title>CSX for files - Disclaimer</title>
                <meta name='description' content='This page have Disclaimer for csxforfiles.com' />
                <link rel='canonical' href='https://csxforfiles.com/Disclaimer' />
            </Helmet>
            <div className='space-y-10'>
                <p className='text-lg'>Last Updated: 4 March 2024</p>
                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>Introduction</h2>
                    <p className='text-xl pl-5 font-medium'>
                        csxforfiles.com is a web application designed for viewing, editing, auto editing, viewing statistics, downloading edited/new file and converting CSV files and Excel files. Please read the following disclaimer carefully:                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>No Warranty or Liability</h2>
                    <p className='text-xl pl-5 font-medium'>
                        The information provided by csxforfiles.com is for general informational purposes only. While we strive to ensure accuracy, we make no warranties or representations regarding the completeness, reliability, or suitability of the content. Users are advised to verify any critical information independently.                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        Client-Side Processing
                    </h2>
                    <p className='text-xl pl-5 font-medium'>
                        csxforfiles.com operates entirely on the client device. It does not store or transmit any data to external servers. Users are responsible for ensuring the security and integrity of their files.
                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        No Legal Advice
                    </h2>
                    <p className='text-xl pl-5 font-medium'>
                        csxforfiles.com does not provide legal advice. Users should consult legal professionals for specific legal matters related to their files or data.
                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        Use at Your Own Risk
                    </h2>
                    <p className='text-xl pl-5 font-medium'>
                        By using csxforfiles.com, users acknowledge that they do so at their own risk. We disclaim any liability for damages, losses, or inconvenience arising from the use of our services.
                    </p>
                </div>


                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        Changes to this Disclaimer
                    </h2>
                    <p className='text-xl pl-5 font-medium'>
                        We reserve the right to update this Disclaimer as needed.
                        Any changes will be posted on this page.
                    </p>
                </div>

                <div className='space-y-2'>
                    <h2 className='text-3xl font-bold'>
                        Contact Us
                    </h2>
                    <p className='text-xl pl-5 font-medium'>
                        If you have any questions or concerns regarding our Disclaimer, please contact us at
                        <a className='underline pl-2' href="mailto: admin@csxforfiles.com">admin@csxforfiles.com</a>.
                    </p>
                </div>
            </div>
        </>
    )
}
