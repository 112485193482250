import { Pagination, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Table } from '../compnents/Table';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';



export default function ViewDataset() {

    const csv = useSelector((state) => state.file)
    const fileName = useSelector((state) => state.fileName)
    const [emptyCells, setemptyCells] = useState(0);
    const [viewcsv, setviewcsv] = useState(true);
    const [columns, setColomns] = useState(Object.keys(csv[0]));

    //search
    const [searchResult, setSearchResult] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [viewSearch, setviewSearch] = useState(false);


    //bagination
    let [currentPage, setCurrentPage] = useState(1);
    let postsPerPage = 100;
    let lastPostIndex = currentPage * postsPerPage;
    let firstPostIndex = lastPostIndex - postsPerPage;
    let currentPosts = csv.slice(firstPostIndex, lastPostIndex);
    let pages = [];

    //Search bagination 
    let [currentSearchPosts, setCurrenttSearchPage] = useState([]);
    let [Searchpages, setSearchPages] = useState([]);


    for (let i = 1; i <= Math.ceil(csv.length / postsPerPage); i++) {
        pages.push(i);
    }


    const handleSearchPageChange = (page) => {
        let lastSearchPostIndex = page * 100;
        let firstSearchPostIndex = lastSearchPostIndex - 100;
        setCurrenttSearchPage(searchResult.slice(firstSearchPostIndex, lastSearchPostIndex))
    }


    const handleSearch = () => {
        setviewcsv(false)
        let isnum = /^\d+$/.test(searchValue);
        let checkedValue = searchValue
        if (isnum) {
            checkedValue = parseInt(searchValue)
        }
        setSearchResult([]);

        let x = []
        for (let i = 0; i < columns.length; i++) {
            csv.filter((row, index) => {
                if (row[columns[i]] === checkedValue) {
                    setSearchResult(prev => ([...prev, row]));
                    x.push(row)
                }
            })
        }

        setCurrenttSearchPage(x.slice(firstPostIndex, lastPostIndex));

        let searchpages = []
        for (let i = 1; i <= Math.ceil(x.length / postsPerPage); i++) {
            searchpages.push(i);
        }

        setSearchPages(searchpages)


        setviewSearch(true)
    }

    useEffect(() => {
        csv.map((row) => {
            Object.values(row).map((value) => {
                if (value === null || value === '') {
                    setemptyCells(prev => prev + 1)
                }
            })
        })
    }, [])


    return (
        <>
            <Helmet>
                <title>View CSV file. View Excel File</title>
                <meta name='description' content='Display all the data in a CSV or XLSM(Excel) file.' />
                <link rel='canonical' href='https://csxforfiles.com/Display_File' />
            </Helmet>

            <div className='absolute left-10'>
                <Tooltip title="Back">
                    <Link to='/Operations'>
                        <ArrowBackIcon fontSize='medium' />
                    </Link>
                </Tooltip>
            </div>

            <div className='flex items-end justify-center p-2 '>
                <h1 className='text-4xl font-semibold'>Viewing {fileName} File</h1>
            </div>

            <div className='flex flex-wrap items-center justify-center space-x-1 p-2 text-lg'>
                <p>Number of Rows: {csv.length} -</p>
                <p>Number of Columns: {Object.keys(csv[0]).length} -</p>
                <p>Number of empty cells: {emptyCells} ({(emptyCells * 100 / (csv.length * Object.keys(csv[0]).length)).toFixed(3)}%)</p>
            </div>

            <div className='flex flex-wrap items-center justify-between pb-4 pt-9'>
                <search className='flex flex-wrap items-center '>
                    <div className='border border-stone-400 w-fit rounded-lg shadow-lg'>
                        <input
                            id="search"
                            name="search"
                            className='h-11 p-2 bg-white bg-opacity-10 rounded-lg focus:outline-none'
                            type="text"
                            placeholder='Search for any value '
                            onChange={e => setSearchValue(e.target.value)} />
                        <button
                            className='border-l px-3'
                            onClick={handleSearch}>
                            <SearchOutlinedIcon fontSize='medium' className='mr-1 mb-[2px] text-slate-700' />
                            <span className='text-slate-700'>Search</span>
                        </button>
                    </div>
                    {viewSearch &&
                        <p className='px-4 text-center text-xl font-bold'>Found <span className=' bg-[#1976d2] py-1 px-2 text-white rounded'>{searchResult.length}</span> Times</p>
                    }
                </search>

                {/* show / hide Table */}
                <div className='flex justify-center items-center p-2'>
                    <button
                        className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden  rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white"
                        onClick={() => {
                            setviewSearch(false)
                            setSearchResult([])
                            setviewcsv(!viewcsv)
                        }}>
                        {viewcsv ?
                            <span className="flex px-5 py-2.5 space-x-2 transition-all ease-in duration-75 bg-[#f9f7f7] rounded-md group-hover:bg-opacity-0">
                                <VisibilityOffIcon />
                                <p>Hide File</p>
                            </span>
                            :
                            <span className="flex px-5 py-2.5 space-x-2 transition-all ease-in duration-75 bg-[#f9f7f7] rounded-md group-hover:bg-opacity-0">
                                <RemoveRedEyeOutlinedIcon />
                                <p>View File</p>
                            </span>
                        }
                    </button>
                </div>

            </div >

            {viewcsv &&
                <>
                    <Table colNames={csv} rows={currentPosts} />
                    <div className='flex h-28 w-full justify-center items-center '>
                        <Pagination
                            onChange={(event, page) => { setCurrentPage(page) }}
                            count={pages.length}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size='large' />
                    </div>
                </>
            }

            {viewSearch &&
                <>
                    <Table colNames={csv} rows={currentSearchPosts} />
                    <div className='flex h-28 w-full justify-center items-center '>
                        <Pagination
                            onChange={(event, page) => { handleSearchPageChange(page) }}
                            count={Searchpages.length}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size='large' />
                    </div>
                </>

            }

        </>
    )
}
