import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ChartBar, ChartPie } from '../Charts/Charts';

export default function AllFileStatistics() {
  const csv = useSelector((state) => state.file)
  const [emptyCells, setemptyCells] = useState(0);
  const [fillCells, setfillCells] = useState(0);
  const [dataForchartpie, setdataForchartpie] = useState([]);
  // const dataForchartpie = [];
  const [View, setView] = useState(false);


  const emptyAndFill = (data) => {
    const filledValues = data.reduce((acc, row) => {
      Object.values(row).forEach((value) => {
        if (value !== null && value !== '' && value !== undefined) {
          acc.fillCells += 1
        } else {
          acc.emptyCells += 1
        }
      })
      return acc
    }, { fillCells: 0, emptyCells: 0 })

    setfillCells(filledValues.fillCells)
    setemptyCells(filledValues.emptyCells)

    setdataForchartpie(prev => [...prev, { id: 0, value: filledValues.fillCells, label: 'Fill Cells' }, { id: 1, value: filledValues.emptyCells, label: 'Empty Cells' }])
    setView(true)
  }



  useEffect(() => {
    emptyAndFill(csv)
  }, [csv])

  return (
    <div className=''>
      <h2 className='font-semibold text-2xl' id='AllFile'>All File Statistics</h2>
      {View ?
        <>
          <div className='flex space-x-1 p-2'>
            <p>Number of Rows: {csv.length}, </p>
            <p>Number of Columns: {Object.keys(csv[0]).length} </p>
          </div>
          <p className='px-2'><span className='font-bold'>{(emptyCells * 100 / (csv.length * Object.keys(csv[0]).length)).toFixed(2)}%</span> of cells are empty</p>
          <div className='flex justify-around'>
            <ChartBar Y={[fillCells, emptyCells]} X={['Fill Cells', 'Empty Cells']} />
            <ChartPie data={dataForchartpie} />
          </div>
        </>
        :
        <p>Loading...</p>
      }

    </div>
  )
}
