import { Tooltip } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import EmailIcon from "@mui/icons-material/Email";

export default function About() {
  return (
    <>
      <Helmet>
        <title>About CSX</title>
        <meta name='description' content='Information about the website and the developers of this website.' />
        <link rel='canonical' href='https://csxforfiles.com/About' />
      </Helmet>

      <article className='py-4 space-y-9'>
        <h1 className="text-center text-2xl font-bold">About Us</h1>

        <div className='space-y-6'>

          <div className='space-y-1'>
            <h2 className="text-lg font-semibold">Introduction</h2>
            <p className='pl-4'>
              Welcome to CSX For Files! We provide an online secure and free platform where users can upload, display file, view Statistics, edit, and convert their CSV and XLSX files. The most important thing is that all the work is done on the client's browser - Our platform don't save or see anything.
            </p>
          </div>

          <div className='space-y-1'>
            <h2 className="text-lg font-semibold">Our Mission</h2>
            <p className='pl-4'>
              Our mission is to provide a completely secure, free, and user-friendly platform for anyone who wants to work with CSV or XLSX files. We value the privacy of our users, and that’s why all the processing is done in the user’s browser. We don’t see or save any data. Our platform is designed with a focus on user experience, ensuring it’s easy to navigate and use.</p>
          </div>

          <div className='space-y-1'>
            <h2 className="text-lg font-semibold">Our Unique Selling Proposition</h2>
            <p className='pl-4'>
              What sets us apart is our commitment to security and privacy. Unlike other platforms, we ensure that the file remains in the user's browser. This means we don't see or save anything. Plus, our platform is completely free to use.
            </p>
          </div>

          <div className='space-y-1'>
            <h2 className="text-lg font-semibold">History</h2>
            <p className='pl-4'>
              Our website is new, and we're excited about the journey ahead. We're committed to continually improving our platform and adding more features in the future.
            </p>
          </div>

          <div className='space-y-1'>
            <h2 className="text-lg font-semibold">Team</h2>
            <p className='pl-4'>
              Our team consists of two dedicated Information Technology engineers brothers who are working hard to provide the best possible service to our users.
            </p>
          </div>

          <div className='space-y-1'>
            <h2 className="text-lg font-semibold">Contact Us</h2>
            <div className='flex items-center flex-wrap pl-4 lg:space-x-5'>
              <p className=''>
                If you have any questions or would like more information,or have a feedback, please don't hesitate to contact us at our email.
              </p>
              <div className='flex space-x-5 text-center'>
                <a className='flex items-center p-1 rounded-full hover:bg-slate-200' href="mailto: admin@csxforfiles.com">
                  <span className='sr-only'>Email: admin@csxforfiles.com</span>
                  <Tooltip title="Email: admin@csxforfiles.com">
                    <EmailIcon className="text-yellow-300" />
                  </Tooltip>
                </a>
              </div>
            </div>
          </div>

        </div>
      </article>
    </>
  )
}
