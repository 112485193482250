import React, { useState } from 'react'
import Preprocessing from '../compnents/preprocessing';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
// import { adddata } from '../redux/DataSlice';

export default function EditDataSet() {
  const [tempCSV, setTempCSV] = useState(useSelector((state) => state.file))

  return (
    <div>
      <Helmet>
        <title>Edit CSV file. Edit Excel File</title>
        <meta name='description' content='Search in your file and Delelte columns and rows and edit any value in you CSV file or XLSX(Excel) file' />
        <link rel='canonical' href='https://csxforfiles.com/Edit_File' />
      </Helmet>


      <Preprocessing tempCSV={tempCSV} setTempCSV={setTempCSV} />
    </div>
  )
}
