import React from 'react'
import AllFileStatistics from '../compnents/Statistics/AllFileStatistics'
import NumericalColumnstatistics from '../compnents/Statistics/NumericalColumnStatistics'
import TextColumnsStatistics from '../compnents/Statistics/TextColumnsStatistics'
import { Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

export default function StatisticsPage() {
    const fileName = useSelector((state) => state.fileName)

    return (
        <>
            <Helmet>
                <title>CSV Statistics. Excel Statistics</title>
                <meta name='description' content='View the statistics of a file using charts, like number of rows, columns, empty cells and Most repeted value in text columns and min, max, avg in numerical columns .' />
                <link rel='canonical' href='https://csxforfiles.com/File_Statistics' />
            </Helmet>

            <div className='flex w-full justify-evenly items-center text-center h-10 text-xl font-semibold space-x-2 '>
                {/* Back button */}
                <div className='absolute left-1 md:left-6'>
                    <Tooltip title="Back">
                        <Link to='/Operations'>
                            <ArrowBackIcon fontSize='medium' />
                        </Link>
                    </Tooltip>
                </div>
                <a
                    href='#AllFile'
                    className='w-full px-5 py-4 rounded-2xl hover:bg-slate-500 hover:text-white transition duration-300'
                >
                    All File
                </a>
                <div className='w-[1px] h-full bg-black bg-opacity-10' />
                <a
                    href='#NumericalColumns'
                    className='w-full px-5 py-4 rounded-2xl hover:bg-slate-500 hover:text-white transition duration-300'
                >
                    Numerical Columns
                </a>
                <div className='w-[1px] h-full bg-black bg-opacity-10' />
                <a
                    href='#TextColumns'
                    className='w-full px-5 py-4 rounded-2xl hover:bg-slate-500 hover:text-white transition duration-300'
                >
                    Text Columns
                </a>
            </div>



            <div className='pt-10 space-y-7'>
                <h1 className='text-4xl font-bold'>Display statistics for {fileName} file</h1>
                <AllFileStatistics />
                <hr />
                <NumericalColumnstatistics />
                <hr />
                <TextColumnsStatistics />
            </div>
        </>
    )
}
