import React, { forwardRef, useState } from 'react'
import Fill from '../../img/fill.png'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel,  Radio, RadioGroup, Slide, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { adddata } from '../../redux/DataSlice';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AutoFillEmptyCells({ tempCSV, setTempCSV, setOpen, setbarType, setmassege }) {

    const [openDialog, setOpenDialog] = useState(false);
    const [ColumnToDelete, setColumnToDelete] = useState('');
    const [fillValue, setfillValue] = useState('');
    const dispatch = useDispatch()


    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleDeletecolumns = (key, Value) => {
        const onlyNumbers = /^\d+$/.test(Value);
        let fillValue2

        if (onlyNumbers) {
            const number = parseInt(Value, 10);
            fillValue2 = number;
        } else {
            fillValue2 = Value;
        }

        if (key === '') {
            setmassege('Chosse Column')
            setbarType('error')
            setOpen(true)
        }

        else {
            let newArr = tempCSV.map(obj => {
                if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
                    return { ...obj, [key]: fillValue2 };
                }
                return obj;
            });
            setTempCSV(newArr)
            dispatch(adddata(newArr))

            handleClose()
        }
    };

    return (
        <div>
            <button
                onClick={e => setOpenDialog(true)}
                className='border-2 border-sky-600 rounded-lg text-left hover:scale-105 transition duration-500 shadow-md m-3'>
                <div className='w-72 h-32 flex justify-start items-center px-5 py-3 space-x-4 '>
                    <img src={Fill} width='64' height='64' className='w-16 h-16' alt='Auto fill empty cells' />
                    <div>
                        <span className='text-xl font-bold'>Fill empty cells</span>
                        <br />
                        <span className='text-xs'>Fill all Empty Cells with specific value</span>
                    </div>
                </div>
            </button>


            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth='md'
                fullWidth
          >
                <DialogTitle>{"Fill Empty Cells"}</DialogTitle>
                <DialogContent className='space-y-8 '>
                    <FormControl>
                        <Typography id="demo-row-radio-buttons-group-label">Choose column:</Typography>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            // value={ColumnToDelete}
                            className='pl-2'
                            onChange={(e) => setColumnToDelete(e.target.value)}
                        >
                            {Object.keys(tempCSV[0]).map(key => (
                                <FormControlLabel value={key} control={<Radio />} label={key} key={key} />
                            ))}

                        </RadioGroup>
                    </FormControl>
                    <Box>
                        <Typography>
                            Enter value to fill the cells:
                        </Typography>
                        <Box className='pl-2'>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="name"
                                name="email"
                                label="Value"
                                type="email"
                                fullWidth
                                variant="standard"
                                onChange={(event) => setfillValue(event.target.value)}
                            />
                        </Box>
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>cancel</Button>
                    <Button onClick={() => handleDeletecolumns(ColumnToDelete, fillValue)} color='success' variant="contained">Done</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
