import { Link } from 'react-router-dom'
import csvImg from '../../img/csv-file.png'
import xlsxImg from '../../img/xlsx-file.png'

export default function MainBlogPage() {
    return (
        <>
            <div className='flex justify-evenly items-center flex-wrap space-y-10 md:space-y-0 py-10'>
                <Link to='/Blogs/CSV_File_Blog' className='w-80 flex justify-center items-center border px-6 py-3 rounded-xl hover:scale-105 transition duration-500 shadow-lg'>
                    <div className='w-full space-y-7'>
                        <div className='w-full flex justify-center items-center'>
                            <img src={csvImg} className='w-32 h-32' />
                        </div>
                        <div>
                            <h2 className='text-xl font-semibold text-center'>CSV Blog</h2>
                        </div>
                    </div>
                </Link>
                <Link to='/Blogs/XLSX_File_Blog' className='w-80 flex justify-center items-center border px-6 py-3 rounded-xl hover:scale-105 transition duration-500 shadow-lg'>
                    <div className='w-full space-y-7'>
                        <div className='w-full flex justify-center items-center'>
                            <img src={xlsxImg} className='w-32 h-32' />
                        </div>
                        <div>
                            <h2 className='text-xl font-semibold text-center'>XLSX Blog</h2>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='flex justify-evenly items-center'>
                <Link to='/Operations' className='border-2 rounded-lg px-9 py-5 text-lg font-semibold border-[#3F72AF] hover:scale-105 transition duration-500 shadow-md'>
                    Go to Operation Page
                </Link>
            </div>
        </>
    )
}
