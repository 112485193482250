import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import logo from '../img/csx logo.svg';

export default function Navbar() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <nav className="flex h-[10dvh] justify-between items-center px-5 md:px-12 bg-[#3F72AF] text-white drop-shadow-lg space-x-3">

            <Link to="/" className="sm:text-xl md:text-2xl font-semibold tracking-wide">
                {/* <img src={logo} className="h-[15dvh] " /> */}
                CSX For Files
            </Link>

            {/* larg screens */}
            <div className="hidden md:flex flex-wrap space-x-5 justify-end ">
                <Link to="/Operations" className="">
                    Operations
                </Link>
                <Link to="/Convert" className="">
                    Convert File
                </Link>
                <Link to="/Blogs" className="">
                    Blogs
                </Link>
                <Link to="/About" className="">
                    About
                </Link>
            </div>

            {/* small screens */}
            <div className="md:hidden ">
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon style={{ color: 'white' }} />
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: 48 * 4.5,
                            width: '20ch',
                        },
                    }}
                >

                    <MenuItem>
                        <Link to="/Operations" onClick={handleClose} className="w-full h-full px-2 py-1">
                            Operations
                        </Link>
                    </MenuItem>

                    <MenuItem>
                        <Link to="/Convert" onClick={handleClose} className="w-full h-full px-2 py-1">
                            Convert File
                        </Link>
                    </MenuItem>

                    <MenuItem>
                        <Link to="/Blogs" onClick={handleClose} className="w-full h-full px-2 py-1">
                            Blogs
                        </Link>
                    </MenuItem>

                    <MenuItem>
                        <Link to="/About" onClick={handleClose} className="w-full h-full px-2 py-1">
                            About
                        </Link>
                    </MenuItem>
                </Menu>
            </div>


        </nav>
    );
}
