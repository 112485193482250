import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ChartBar } from '../Charts/Charts';

export default function TextColumnsStatistics() {
    const csv = useSelector((state) => state.file)
    const [columnNames, setcolumnNames] = useState([])
    const [mostRepetedValueInEveryColunm, setmostRepetedValueInEveryColunm] = useState([])
    const [coutValuesInColumns, setcoutValuesInColumns] = useState([])


    const getMostRepeatedValue = (key) => {
        let frequencyCounter = {};

        // Count the frequency of each value
        for (let i = 0; i < csv.length; i++) {
            let value = csv[i][key];
            if (frequencyCounter[value] == null) {
                frequencyCounter[value] = 1;
            } else {
                frequencyCounter[value]++;
            }
        }

        // Find the value with the highest frequency
        let mostRepeatedValue = null;
        let maxFrequency = -Infinity;
        for (let value in frequencyCounter) {
            if (frequencyCounter[value] > maxFrequency) {
                maxFrequency = frequencyCounter[value];
                mostRepeatedValue = value;
            }
        }
        setcolumnNames(prev => [...prev, key])
        setcoutValuesInColumns(prev => [...prev, frequencyCounter])
        return mostRepeatedValue;
    }



    useEffect(() => {
        let x = Object.keys(csv[0])
        Object.values(csv[0]).map((value, index) => {
            let columnName = x[index]
            if (typeof (value) === 'number') {
            }

            else if (typeof (value) === 'string') {
                setmostRepetedValueInEveryColunm(prev => [...prev, getMostRepeatedValue(columnName)])
            }

            else {
                let secondtry = csv[2][x[index]]

                if (typeof (secondtry) === 'number') {

                }
                else if (typeof (secondtry) === 'string') {
                    setmostRepetedValueInEveryColunm(prev => [...prev, getMostRepeatedValue(columnName)])
                }
                else {

                }
            }
        })
    }, [])


    return (
        <div className='pb-7'>
            <h2 className='font-semibold text-2xl' id='TextColumns'>Text Columns Statistics</h2>
            {columnNames.length != 0 ?
                <div>
                    {columnNames.map((key, index) => {
                        let x, y
                        if (Object.keys(coutValuesInColumns[index]).length > 5) {
                            x = Object.keys(coutValuesInColumns[index]).slice(0, 5)
                            y = Object.values(coutValuesInColumns[index]).slice(0, 5)
                        }
                        else {
                            x = Object.keys(coutValuesInColumns[index])
                            y = Object.values(coutValuesInColumns[index])
                        }
                        return (
                            <div key={index}>
                                <div key={index + 1} className='border-b py-2 px-4'>
                                    <div className='flex justify-evenly items-center my-3 space-x-2 flex-wrap '>
                                        <h3 className='font-bold' key={index + 2}>{key}: </h3>
                                        <p key={index + 3}> Most repeted value is <span className='font-semibold'> {mostRepetedValueInEveryColunm[index]}</span></p>
                                        {x && <ChartBar Y={y} X={x} key={index} />}
                                    </div>
                                </div>
                            </div>)
                    })}
                </div>
                :
                <p className='px-5 py-5 '>There is No text columns in your file</p>
            }

        </div>
    )
}
