import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className='w-full flex justify-center items-center py-6 bg-[#F9F7F7] space-x-6 font-medium'>
            <Link to='/PrivacyPolicy' className='underline'>
                Privacy Policy
            </Link>
            <Link to='/Disclaimer' className='underline'>
                Disclaimer
            </Link>
        </div>
    )
}
