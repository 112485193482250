import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

export const ProtectRoute = () => {
    return (
        <>
            <Helmet>
                <title>Not Allowed</title>
            </Helmet>

            <div className='space-y-16'>
                <div className='w-full flex justify-center pt-16 px-7'>
                    <h1 className="text-5xl font-bold text-gray-800">
                        Please come to here after you upload your file.
                    </h1>
                </div>
                <div className='w-full flex items-center justify-center'>
                    <Link to='/Operations' className='border-2 rounded-lg px-9 py-5 text-xl font-semibold border-[#3F72AF] hover:scale-105 transition duration-150'>
                        Go to Operation page
                    </Link>
                </div>
            </div>
        </>
    )
}
