import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { SnackbarComp } from '../compnents/snackbar'
import InputCSV from '../compnents/inputFile'

import viewfile from '../img/viewfile.png'
import editfile from '../img/edit.png'
import statistics from '../img/statistics.png'
import auto from '../img/auto.png'
import { DownloadFile } from '../compnents/DownloadFile'
import { Helmet } from 'react-helmet-async'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function MainPage() {

  const fileName = useSelector((state) => state.fileName)
  const [open, setOpen] = useState(false)
  const [barType, setbarType] = useState('success')
  const [massege, setmassege] = useState('')

  const handleClick = (e) => {
    if (!fileName) {
      e.preventDefault()
      setbarType('error')
      setmassege('Upload file first')
      setOpen(true)
    }
  }

  return (
    <>
      <Helmet>
        <title>View, View Statistics, Edit and Auto Edit your CSV Files and Excel file (XLSX file)</title>
        <meta name='description' content='open CSV file, open excel file, open xlsx file, CSV editor, Excel editor, XLSX editor, CSV auto editor, Excel auto editor, CSV file statistics, excel file statistics' />
        <link rel='canonical' href='https://csxforfiles.com/Operations' />
      </Helmet>

      <h1 className="text-center text-4xl font-bold py-7 ">Upload your CSV file, Excel file (XLSX file) and start working</h1>

      <div className='w-ful py-5 space-y-6'>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className='font-bold'
          >
            What can you do with your CSV file or Excel file (XLSX file) on the operations page !?
          </AccordionSummary>
          <AccordionDetails className='space-y-5 mx-1 md:mx-4'>

            <p className=''>Welcome to our platform, a user-friendly and free online editor and viewer and more for CSV files and Excel file (XLSX file) designed to empower you with full control over your CSV file and Excel file. We understand the importance of data privacy, which is why all processing happens directly on your device. Rest assured, none of your data is ever seen, saved, or shared by us. Experience seamless file management like never before, all while keeping your data on your device.</p>

            <div>
              <h2 className='text-lg font-bold '>1. Open CSV file, open Excel file (open XLSX file):</h2>
              <p className='px-2 md:px-5'>
                After uploading your CSV file or Excel file (XLSX file), you can open CSV file or open Excel file in table format on the web page. You can also search in CSV file or search in Excel file for any data within the tables using the search box. This feature is useful for viewing and analyzing your data online.
              </p>
            </div>

            <div>
              <h2 className='text-lg font-bold '>2. CSV editor, Excel editor (XLSX editor):</h2>
              <p className='px-2 md:px-5'>
                After uploading your CSV file or Excel file (XLSX file), you can edit CSV file and edit Excel file (edit XLSX file). You can edit the cell data by double-clicking on the cell. You can delete columns by clicking on the delete button that is beside the column name. Likewise, you can rename the column names by clicking on the edit button that is beside the column name, and delete rows by clicking on the delete button at the end of every row. You can search for any value within the tables using the search box, and in the search table, you can edit cell data by double-clicking on a cell, and you can delete the rows that have the searched value by clicking on the delete button at the end of every row. This feature is useful for modifying and managing your data online.
              </p>
            </div>

            <div>
              <h2 className='text-lg font-bold '>3. Auto-edit CSV file, Auto-edit Excel file (XLSX file):</h2>
              <p className='px-2 md:px-5'>
                After uploading your CSV file or Excel file (XLSX file), you can use the auto-edit feature to perform three operations on your uploaded CSV file or Excel file (XLSX file). The first operation is the auto-delete column, which checks the percentage of missing or invalid values in each column. If the percentage is higher than a specific threshold (that you enter), the column will be deleted. This feature is useful for cleaning your data and removing unnecessary columns. The second operation is to delete all rows that have empty values. This feature is useful for reducing the size of your data and removing irrelevant rows. The third operation is to fill in empty cells in a selected column with a value that you enter. This feature is useful for completing your data and avoiding errors.
              </p>
            </div>

            <div>
              <h2 className='text-lg font-bold '>4. Excel statistics, CSV statistics:</h2>
              <p className='px-2 md:px-5'>
                After uploading your CSV file or Excel file (XLSX file), you can see Excel statistics, see CSV statistics in various charts. The statistics include the number of rows and columns, the number of filled and empty cells and their percentages, and the summary of each column. For numerical columns, you can see the minimum, maximum, and average values for every column in line charts. For text columns, you can see the most repeated value in the column and some of the other values, along with the number of times they appear in bar charts. This feature is useful for visualizing and understanding your data better.
              </p>
            </div>

            <div>
              <h2 className='text-lg font-bold '>5. Download your new CSV file or Excel file (XLSX file):</h2>
              <p className='px-2 md:px-5'>
                You can download your new Excel file (XLSX file) or download your new CSV file after editing it, and you can download it as a CSV file or download it as Excel file (download it as XLSX file).
              </p>
            </div>

          </AccordionDetails>
        </Accordion>


        <InputCSV />

        <div className="flex py-5 items-center">
          <div className="flex-grow border-t border-gray-700" />
          <p className="flex-shrink mx-4 text-gray-700">Have Fun With Your File</p>
          <div className="flex-grow border-t border-gray-700" />
        </div>

        <div className='flex justify-evenly flex-wrap '>

          <Link
            to='/View_File'
            onClick={e => handleClick(e)}
            className={`border-2 border-sky-600 rounded-lg text-left hover:scale-105 transition duration-500 shadow-md m-3 ${fileName ? '' : 'cursor-not-allowed'}`}
          >
            <div className='w-72 h-32 flex justify-start items-center px-5 py-3 space-x-4 '>
              <img src={viewfile} width='64' height='64' className='w-16 h-16' title='Display CSV or XLSX(Excel) file' alt='View file' />
              <div>
                <h2 className='text-xl font-bold'>View</h2>
                <span className='text-xs'>View CSV file. View Excel file </span>
              </div>
            </div>
          </Link>

          <Link
            to='/Edit_File'
            onClick={e => handleClick(e)}
            className={`border-2 border-sky-600 rounded-lg text-left hover:scale-105 transition duration-500 shadow-md m-3 ${fileName ? '' : 'cursor-not-allowed'}`}
          >
            <div className='w-72 h-32 flex justify-start items-center px-5 py-3 space-x-4 '>
              <img src={editfile} width='64' height='64' className='w-16 h-16' title='Edit CSV or XLSX(Excel) file' alt='Edit file' />
              <div>
                <h2 className='text-xl font-bold'>Edit</h2>
                <span className='text-xs'>Edit CSV file. Edit Excel file </span>
              </div>
            </div>
          </Link>

          <Link
            to='/Auto_Edit'
            onClick={e => handleClick(e)}
            className={`border-2 border-sky-600 rounded-lg text-left hover:scale-105 transition duration-500 shadow-md m-3 ${fileName ? '' : 'cursor-not-allowed'}`}
          >
            <div className='w-72 h-32 flex justify-start items-center px-5 py-3 space-x-4 '>
              <img src={auto} width='64' height='64' className='w-16 h-16' title='Auto edit CSV or XLSX(Excel) file' alt='Auto Edit file' />
              <div>
                <h2 className='text-xl font-bold'>Auto Edit</h2>
                <span className='text-xs'>Delete empty rows and columns or fill empty cells in your file automatically.</span>
              </div>
            </div>
          </Link>

        </div>

        <div className="flex justify-evenly flex-wrap ">

          <Link
            to="/File_Statistics"
            onClick={e => handleClick(e)}
            className={`border-2 border-sky-600 rounded-lg text-left hover:scale-105 transition duration-500 shadow-md m-3 ${fileName ? '' : 'cursor-not-allowed'}`}
          >
            <div className='w-72 h-32 flex justify-start items-center px-5 py-3 space-x-4 '>
              <img src={statistics} width='64' height='64' className="w-16 h-16" title='Statistics about CSV or XLSX(Excel) file' alt='Statistics about CSV or XLSX(Excel) file' />
              <div>
                <h2 className="text-xl font-bold">
                  Statistics
                </h2>
                <span className="text-xs">
                  Get statistics about your file and columns
                </span>
              </div>
            </div>
          </Link>

          <DownloadFile fileName={fileName} setOpen={setOpen} setbarType={setbarType} setmassege={setmassege} />

        </div>

        <SnackbarComp open={open} setOpen={setOpen} barType={barType} massege={massege} />

      </div >
    </>
  );
}
